<template>
  <div>
    <div class="uk-text-large">{{ variations[0].type.name }} disponibles</div>
    <div class="glide uk-position-relative" :id="glideId">
      <div
        class="glide__track"
        style="padding-left: 50px; padding-right: 50px"
        data-glide-el="track"
      >
        <ul class="glide__slides">
          <li
            class="glide__slide"
            v-for="(variation, index) in displayVariations"
            :key="variation.id"
            :title="variation.value"
          >
            <button
              class="uk-button uk-border-rounded variation-card uk-width-1-1"
              :class="{
                'uk-button-primary':
                  activeVariationId && activeVariationId === variation.id,
              }"
              @click="setInternalVariation(variation, index)"
            >
              <div class="uk-position-relative">
                <div style="height: 130px">
                  <span
                    style="
                      display: inline-block;
                      height: 100%;
                      vertical-align: middle;
                    "
                  >
                  </span>
                  <img
                    style="vertical-align: middle"
                    class="uk-border-rounded"
                    :src="
                      variation.displayImage ? variation.displayImage.url : ''
                    "
                    width="100%"
                    alt
                  />
                </div>
                <button
                  test="add-variation-button"
                  v-if="
                    variation.provider_code &&
                    (storefrontStorefront.type === 'b2b'
                      ? !(variation.id in quotableIds) && variation.stock > 0
                      : variation.storefront_redeemables.length &&
                        variation.storefront_redeemables[
                          variation.storefront_redeemables.length - 1
                        ].current_stock > 0 &&
                        !(
                          variation.storefront_redeemables[
                            variation.storefront_redeemables.length - 1
                          ].id in quotableIds
                        ))
                  "
                  class="
                    uk-position-absolute
                    uk-position-top-right
                    uk-button-primary
                    uk-border-rounded
                  "
                  style="padding: 3px"
                  uk-icon="plus"
                  @click.stop="
                    addQuotable(
                      storefrontStorefront.type === 'b2b'
                        ? variation.id
                        : variation.storefront_redeemables[
                            variation.storefront_redeemables.length - 1
                          ].id
                    )
                  "
                ></button>
                <button
                  v-if="
                    variation.provider_code &&
                    (storefrontStorefront.type === 'b2b'
                      ? variation.id in quotableIds
                      : variation.storefront_redeemables.length &&
                        variation.storefront_redeemables[
                          variation.storefront_redeemables.length - 1
                        ].id in quotableIds)
                  "
                  class="
                    uk-position-absolute
                    uk-position-top-right
                    uk-button-primary
                    uk-border-rounded
                  "
                  style="padding: 3px"
                  uk-icon="check"
                  @click.stop="
                    removeQuotable(
                      storefrontStorefront.type === 'b2b'
                        ? variation.id
                        : variation.storefront_redeemables[
                            variation.storefront_redeemables.length - 1
                          ].id
                    )
                  "
                ></button>
              </div>
              <marquee-text
                v-if="
                  activeVariationId &&
                  activeVariationId === variation.id &&
                  variation.value.length > 6
                "
                :duration="3"
                :repeat="3"
              >
                {{ variation.value }}

                <span class="uk-margin-right uk-margin-left"></span>
              </marquee-text>
              <div class="uk-text-truncate" v-else>{{ variation.value }}</div>
              <div v-if="canSeeStock">
                <div v-if="storefrontStorefront.type === 'b2b'">
                  <div v-if="variation.stock">
                    <div class="uk-flex uk-flex-center uk-flex-middle">
                      <div class="uk-text-small">
                        <div>Stock</div>
                        <div>
                          <span v-if="variation.stock <= 100000">{{
                            formatThousands(variation.stock)
                          }}</span>
                          <span v-else> Ilimitado </span>
                        </div>
                      </div>
                      <div
                        class="uk-margin-small-left"
                        uk-spinner
                        v-if="updatingProviderInfo"
                      ></div>
                    </div>
                    <!-- <div class="uk-text-small">
                      ({{ formatDatetimeElapsed(variation.stock_updated_at) }})
                    </div> -->
                  </div>
                  <div
                    v-else-if="variation.children.length === 0"
                    class="uk-text-small"
                  >
                    <div>No</div>
                    <div>disponible</div>
                  </div>
                </div>
                <div
                  v-if="
                    storefrontStorefront.type === 'redeem' &&
                    variation.storefront_redeemables.length
                  "
                >
                  <div class="uk-flex uk-flex-center uk-flex-middle">
                    <div class="uk-text-small">
                      Stock:
                      {{
                        formatThousands(
                          variation.storefront_redeemables[
                            variation.storefront_redeemables.length - 1
                          ].current_stock
                        )
                      }}
                    </div>
                  </div>
                </div>
              </div>
              <!-- <div
                v-if="canSeePriceUpdates && variation.prices_updated_at"
                class="uk-text-small"
              >
                Precio del
                {{ formatShortDatetime(variation.prices_updated_at) }}
              </div> -->
            </button>
          </li>
        </ul>
      </div>

      <div
        class="uk-flex uk-flex-between glide__arrows"
        data-glide-el="controls"
      >
        <a
          class="uk-link-reset uk-position-center-left uk-flex uk-items-center"
          style="background: white; height: 105%"
          data-glide-dir="<"
        >
          <div uk-icon="icon: chevron-left; ratio: 2;" class="uk-flex"></div>
        </a>
        <a
          class="uk-link-reset uk-position-center-right uk-flex uk-items-center"
          style="background: white; height: 105%"
          data-glide-dir=">"
        >
          <div uk-icon="icon: chevron-right; ratio: 2;" class="uk-flex"></div>
        </a>

        <div
          class="slider__bullets glide__bullets uk-flex uk-flex-center"
          data-glide-el="controls[nav]"
        >
          <button
            v-for="(variation, index) in displayVariations"
            :key="'dotnav-' + variation.id"
            class="slider__bullet glide__bullet glide__bullet uk-dotnav"
            :data-glide-dir="'=' + index"
            @click="setInternalVariation(variation, index)"
          ></button>
        </div>
      </div>
    </div>

    <VariationSelector
      v-if="activeVariation && activeVariation.children.length !== 0"
      :variations="activeVariation.children"
      :setVariation="setVariation"
      :updatingProviderInfo="updatingProviderInfo"
      :canSeeStock="canSeeStock"
      :canSeePriceUpdates="canSeePriceUpdates"
      :quotableIds="quotableIds"
    />
  </div>
</template>

<script>
import Glide from "@glidejs/glide";
import { mapGetters } from "vuex";
import MarqueeText from "vue-marquee-text-component";

export default {
  name: "VariationSelector",

  components: {
    MarqueeText,
  },

  props: [
    "variations",
    "canSeeStock",
    "canSeePriceUpdates",
    "setVariation",
    "updatingProviderInfo",
    "quotableIds",
  ],

  data() {
    return {
      glideId:
        "glider-" +
        ([1e7] + -1e3 + -4e3 + -8e3 + -1e11).replace(/[018]/g, (c) =>
          (
            c ^
            (crypto.getRandomValues(new Uint8Array(1))[0] & (15 >> (c / 4)))
          ).toString(16)
        ),
      activeVariationId: this.variations[0].id,
      glider: null,
    };
  },

  computed: {
    ...mapGetters(["storefrontStorefront"]),
    displayVariations() {
      return this.variations.map((variation) => ({
        ...variation,
        displayImage: this.extractImage(variation),
      }));
    },
    activeVariation() {
      return this.variations.find(
        (variation) => variation.id === this.activeVariationId
      );
    },
  },

  methods: {
    addQuotable(variationId) {
      this.$root.$emit("add-quotable", variationId);
    },
    removeQuotable(variationId) {
      this.$root.$emit("remove-quotable", variationId);
    },
    extractImage(variation) {
      if (
        this.storefrontStorefront.type === "b2b" &&
        variation.media.length !== 0
      ) {
        return variation.media[0];
      }
      if (
        this.storefrontStorefront.type === "redeem" &&
        variation.storefront_redeemables.length &&
        variation.storefront_redeemables[
          variation.storefront_redeemables.length - 1
        ].media.length !== 0
      ) {
        return variation.storefront_redeemables[
          variation.storefront_redeemables.length - 1
        ].media[0];
      }
      if (variation.children.length !== 0) {
        const childrenImage = variation.children
          .map((children) => this.extractImage(children))
          .filter((image) => image);
        if (childrenImage.length !== 0) {
          return childrenImage[0];
        }
        return null;
      }
      return null;
    },
    setInternalVariation(variation, index) {
      this.activeVariationId = variation.id;
      this.glider.go("=" + index);
      if (variation.provider_code) {
        this.setVariation(variation);
        document
          .getElementById("content-container")
          .scrollIntoView({ behavior: "smooth" });
      } else {
        this.setVariation(null);
      }
    },
    initializeGlider() {
      this.glider = new Glide(document.getElementById(this.glideId), {
        perView: 4,
        breakpoints: {
          750: {
            perView: 3,
          },
          450: {
            perView: 2,
          },
        },
      }).mount();
    },
  },

  mounted() {
    this.initializeGlider();
    if (this.storefrontStorefront.type === "b2b") {
      if (this.variations[0].provider_code) {
        this.setVariation(this.variations[0]);
      }
    } else if (this.storefrontStorefront.type === "redeem") {
      let variationsWithStorefrontRedeemables = this.variations.filter(
        (variation) =>
          variation.storefront_redeemables.length && variation.provider_code
      );
      if (variationsWithStorefrontRedeemables.length) {
        this.setVariation(variationsWithStorefrontRedeemables[0]);
      }
    }
  },

  watch: {
    displayVariations: {
      handler() {
        this.$nextTick(() => {
          this.initializeGlider();
        });
      },
    },
  },
};
</script>

<style scoped>
.variation-card {
  padding: 4px;
}
</style>